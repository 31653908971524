const DEFAULT_MAX_AGE = 86400; // 24 hours
const PAGE_MAX_AGE = 2592000; // 30 days

const shortDefaults = { maxAge: DEFAULT_MAX_AGE };
const pageLongDefaults = { maxAge: PAGE_MAX_AGE };

function makeConfig(maxAge, config) {
  return {
    ...maxAge,
    ...config,
  };
}

export function addCacheTags(config = {}, tags) {
  return {
    ...config,
    cacheTags: [
      ...(config.cacheTags || []),
      ...tags,
    ].filter(x => !!x),
  };
}

const httpCacheConfig = {
  // Short-lived (24 hours) page types.
  landingPage: makeConfig(shortDefaults, { cacheTags: ['landing_page', 'global'] }),
  dailyReplayPage: makeConfig(shortDefaults, { cacheTags: ['episode', 'daily_replay', 'global'] }),
  // Long-lived (30 days) page types.
  blogListPage: makeConfig(pageLongDefaults, { cacheTags: ['blog_listing_page', 'global'] }),
  utilityPage: makeConfig(pageLongDefaults, { cacheTags: ['utility_page', 'global'] }),
  onDemandPage: makeConfig(pageLongDefaults, { cacheTags: ['on_demand_page', 'global'] }),
  keyConceptsPage: makeConfig(pageLongDefaults, { cacheTags: ['key_concepts_page', 'definition', 'global'] }),
  keyConceptsDefinitionPage: makeConfig(pageLongDefaults, { cacheTags: ['definition', 'global'] }),
  definitionPage: makeConfig(pageLongDefaults, { cacheTags: ['definition', 'global'] }),
  episodePage: makeConfig(pageLongDefaults, { cacheTags: ['episode', 'global'] }),
  showPage: makeConfig(shortDefaults, { cacheTags: ['show', 'global'] }),
  blogPostPage: makeConfig(pageLongDefaults, { cacheTags: ['blog_post', 'global'] }),
  talentPage: makeConfig(pageLongDefaults, { cacheTags: ['talent', 'global'] }),
};

export default httpCacheConfig;
