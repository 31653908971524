import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import styled, { css } from 'styled-components';
import { ifProp, switchProp, theme } from 'styled-tools';
import MuiToolbar from '@material-ui/core/Toolbar';
import { useTheme } from '@material-ui/core/styles';
import MuiIconButton from '@material-ui/core/IconButton';
import getDefinitionsTermsQuery from '../../../graphql/queries/getDefinitionsTerms.graphql';
import getDefinitionCategories from '../../../graphql/queries/getDefinitionCategories.graphql';
import { useKeyConceptsContext } from 'context/Page/KeyConcepts';
import MobileMenusWrapper from './components/MobileMenusWrapper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useElementSize from 'hooks/useElementSize';
import Search from './components/Search';
import pageShape from 'shapes/pageShape';
import Icon from 'components/Icon';
import SEO from 'components/SEO';
import Sidebar from './Sidebar';
import Main from './Main';
import useServerCacheHeaders from 'hooks/useServerCacheHeaders';
import httpCacheConfig, { addCacheTags } from 'config/httpCache';

const GridItem = styled(Grid)`
  ${switchProp('$type', {
    main: css`
      background: ${theme('colors.backgroundGray')};
      height: inherit;

      ${(props) => props.theme.breakpoints.up('lg')} {
        flex-grow: 1;
        max-width: calc(100% - 16.25rem);
      }

      ${(props) => props.theme.breakpoints.down('xs')} {
        margin-top: 4.25rem;
      }
    `,
    sidebar: css`
      background-color: ${theme('colors.white')};
      ${(props) => props.theme.breakpoints.up('lg')} {
        flex-basis: 16.25rem;
        max-width: 16.25rem;
      }
      ${({ theme }) => theme.breakpoints.down('xs')} {
        display: none;
      }
    `,
  })}
`;

const Toolbar = styled(MuiToolbar)`
  background: ${theme('colors.white')};
  border-bottom: 0.0625rem solid ${theme('colors.borderGray')};
  padding: 0 0.375rem;
  width: 100%;
  position: fixed;
  z-index: 1105;
`;

const IconButton = styled(MuiIconButton)`
  color: ${theme('colors.black')};
  font-size: 1rem;

  &:hover {
    svg {
      color: ${theme('colors.ttRed')};
    }
  }
`;

KeyConceptsPage.propTypes = {
  page: pageShape,
};

function KeyConceptsPage({ page, ...props }) {
  useServerCacheHeaders(
    addCacheTags(httpCacheConfig.utilityPage, [page.uid, 'header', 'footer'])
  );
  const history = useHistory();
  const { seo, title } = page;
  const theme = useTheme();
  const sidebarRef = useRef(null);
  const { offsetTop, width } = useElementSize(sidebarRef);
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [categoryFilters, setCategoryFilters] = useState({ categoryUids: [] });

  const {
    activeTerm,
    mobileMenuOpen,
    setActiveTerm,
    setSearchResults,
    setSearchTerm,
    toggleMobileMenu,
  } = useKeyConceptsContext();

  useEffect(() => {
    if (history.location.pathname === '/concepts-strategies') {
      setActiveTerm(null);
    }
  }, [activeTerm]);

  const {
    data: categoryData,
    error,
    loading,
  } = useQuery(getDefinitionCategories, {
    onCompleted: () => {
      const categories = categoryData?.definitionCategories?.items?.map(
        (item) => item.uid
      );
      setCategoryFilters({ categoryUids: categories });
    },
  });

  const categories = categoryData?.definitionCategories?.items?.map(
    (item) => item.uid
  );
  const { data: definitionsData, error: definitionsError } = useQuery(
    getDefinitionsTermsQuery,
    {
      variables: {
        filters: {
          displayLearn: true,
        },
      },
    }
  );

  const handleChange = (filterValues = []) => {
    const filterKey = Object.keys(filterValues);
    const filterValue = Object.values(filterValues);

    setCategoryFilters({
      ...categoryFilters,
      [filterKey]: filterValue[0],
    });
  };

  // Search logic setup to happen on change based on typing in the search
  // bar; Search results will be sent to the context and used to filter against
  // in other child components.
  const termsByCategory = definitionsData?.definitions?.items || [];
  const checkForSearchTerm = (event) => {
    const searchTerm = event.toLowerCase();
    const allTitles = termsByCategory.map((item) => ({
      title: item.title.toLowerCase(),
      category: item.category.uid,
    }));

    const results = allTitles.filter((item) => {
      return item.title.includes(searchTerm);
    });

    if (isMobile && !mobileMenuOpen) {
      toggleMobileMenu(activeTerm);
      setSearchTerm(searchTerm);
      setSearchResults(results);
    } else {
      setSearchTerm(searchTerm);
      setSearchResults(results);
    }
  };

  return (
    <>
      <SEO
        seo={activeTerm ? activeTerm?.seo : seo}
        title={activeTerm ? activeTerm?.title : title}
      />
      <Grid container>
        {/* Sidebar - hidden in mobile */}
        {/* As window.innerWidth is unavailable on the server, they default to rendering an empty component during the first mount. So setting initialWidth is necessary here */}
        <Hidden xsDown initialWidth='xl'>
          <GridItem item xs={12} sm={3} lg={2} $type='sidebar' ref={sidebarRef}>
            <Sidebar
              categoryFilters={categoryFilters}
              checkForSearchTerm={checkForSearchTerm}
              categories={categoryData?.definitionCategories}
              handleChange={handleChange}
              offsetTop={offsetTop}
              termsByCategory={termsByCategory}
              width={!width || width >= 260 ? 260 : width}
            />
          </GridItem>
        </Hidden>

        {/* Mobile menu - hidden on desktop */}
        <Hidden smUp implementation='css'>
          <Toolbar $open={mobileMenuOpen}>
            <Search
              checkForSearchTerm={checkForSearchTerm}
              isMobileSearch={true}
            />
            <IconButton onClick={() => toggleMobileMenu(activeTerm)}>
              <Icon
                className='collapse-icon'
                icon={mobileMenuOpen ? 'chevronUp' : 'chevronDown'}
                size={0.8}
              />
            </IconButton>
          </Toolbar>
          <MobileMenusWrapper
            categoryFilters={categoryFilters}
            categories={categoryData?.definitionCategories}
            handleChange={handleChange}
            termsByCategory={termsByCategory}
          />
        </Hidden>

        {/* Main content */}
        <GridItem item xs={12} sm={9} lg={10} $type='main'>
          <Main page={page} offsetTop={offsetTop} {...props} />
        </GridItem>
      </Grid>
    </>
  );
}

export default KeyConceptsPage;
